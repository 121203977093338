import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa"
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#ffd900",
        secondary: "#515151"
      }
    }
  }
});

<template lang="pug">
.page
  schools
  bottom
</template>

<script>
import Schools from "./Schools";
import Bottom from "./Bottom";

export default {
  components: {
    Schools,
    Bottom,
  },
};
</script>

<style>
.page {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: auto 80px;
}
@media screen and (max-width: 960px) {
  .page {
    display: flex;
    flex-direction: column;
  }
}
</style>

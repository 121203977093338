<template lang="pug">
v-app
  index
</template>

<script>
import Index from "./view/Index";

export default {
  components: { Index },
};
</script>

<style>
@import "./style/fonts.css";
@import "./style/main.css";
@import "./style/custom-user.css";
</style>

<template lang="pug">
.d-flex.flex-column.justify-center.align-center
  img.school-img(:src="img", contain)
  span.font-weight-black.text-body-2.mt-2 {{ city }}
  v-btn.text-capitalize.small.grey--text.text--darken-2.font-weight-regular.mt-1(
    v-if="addressLink",
    :href="addressLink",
    target="_blank",
    text,
    small
  )
    v-icon(small, left) fas fa-map-marked-alt
    span.text-center(v-html="addressText")
  v-btn.text-capitalize.small.font-weight-bold.mt-1(
    v-if="whatsappLink",
    :href="whatsappLink",
    target="_blank",
    text,
    small
  )
    v-icon(small, left) fab fa-whatsapp
    span {{ whatsappText }}
  div(v-if="href")
    v-btn.black--text.rounded-pill.mt-1.font-weight-black(
      :href="href",
      color="primary",
      depressed,
      small,
      readonly
    ) Entrar no site
  div(v-else)
    v-btn.text-capitalize.black--text.rounded-pill.mt-1.font-weight-black(
      color="primary",
      depressed,
      small,
      readonly
    ) Em Breve
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    city: {
      type: String,
      required: true,
    },
    addressText: {
      type: String,
    },
    addressLink: {
      type: String,
    },
    whatsappText: {
      type: String,
    },
    whatsappLink: {
      type: String,
    },
    href: {
      type: String,
    },
  },
};
</script>

<style>
.school-img {
  max-width: 180px;
}
</style>

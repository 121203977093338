<template lang="pug">
footer.d-flex.flex-column.flex-md-row
  a.gotham-light.text-h5.text-sm-h4.text-decoration-none.logo(
    href="https://www.grupofasipe.com.br",
    target="_blank"
  )
    img(src="/img/fasipe.png")
  .d-flex.flex-column.flex-lg-row.secondary.w-100.align-center.justify-space-around.pa-4
    span.text-body-2.white--text.text-center © Copyright • Todos os Direitos Reservados • GRUPO FASIPE EDUCACIONAL
    a.gotham-light.text-h5.text-sm-h4.text-decoration-none(
      href="https://www.grupofasipe.com.br",
      target="_blank"
    )
      span.grey--text.text--darken-1 WWW.GRUPOFASIPE.COM.BR
</template>

<script>
export default {};
</script>

<style>
.logo {
  height: 100%;
  width: 40%;
  padding: 12px;
  padding-right: 100px;
}
.logo img {
  object-fit: contain;
  object-position: right;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 960px) {
  .logo {
    padding: 16px;
    height: 90px;
    width: 100%;
  }
  .logo img {
    object-position: center;
  }
}
</style>
